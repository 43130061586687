@import url(https://fonts.googleapis.com/css2?family=Abel&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap);
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/
* {
  z-index: 0;
}
*:focus {
  box-shadow: none !important;
  /* This is to remove the blue outline that comes with Chakra UI, 
  but it's not good for accessibility, but it's so ugly so what we gonna do 🤷‍♂️.  */
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #cbcbcb;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 0.1px solid #b7b7b7;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}

h1,
h2,
h3,
h4 {
  font-family: 'Abel', sans-serif;
}

p {
  font-family: 'Abel', sans-serif;
  text-align: justify;
}

li {
  font-family: 'Abel', sans-serif;
  text-align: justify;
}

.header {
  width: 100%;
  margin-bottom: 2.8vw;
  padding-top: 2.8vw;
  padding-right: 2.8vw;
  padding-left: 2.8vw;
  padding-bottom: 0.5vw;
  top: 0;
  position: fixed;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 1;
}

@media screen and (max-width: 765px) {
  .header {
    padding-top: 5vh;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .color-button-text {
    display: none !important;
  }
}

@media screen and (min-width: 765px) {
  .color-button-icon {
    display: none;
  }
}

.footer {
  width: 100%;
  margin-top: 2.8vh;
  /* height: 40px; */
  position: relative;
  bottom: 0;
  height: 25vh;
  background-color: gray.400;
  text-align: center;
}

.icons-container {
  /* margin: 0 39vw; */
  /* padding: 0 7rem; */
  padding-top: 6vh;
  padding-bottom: 3vh;
}

.nav-links {
  padding-right: 2.8vw;
  /* background-color: orange; */
}

@media screen and (max-width: 765px) {
  /* .nav-socials {
    display: none;
  } */
  .github-header {
    display: none;
  }
  .twitter-header {
    display: none;
  }
  .linkedin-header {
    display: none;
  }
}

.logo {
  font-weight: bold;
  font-size: 1.5rem;
}

.main {
  padding: 0 3rem;
}

@media screen and (max-width: 765px) {
  .main {
    padding: 0 0;
  }
}

.profile {
  padding: 0 6vw;
  top: 11vw;
  margin-top: 22vh;
  /* position: fixed; */
}

@media screen and (max-width: 765px) {
  .profile {
    position: relative;
  }
}

.projects-box {
  /* margin-top: 120px;
  margin-right: 120px; */
  height: 57vh;
  overflow: auto;
  overflow-x: hidden;
  /* background-color: orange; */
}

@media screen and (max-width: 765px) {
  .projects-box {
    height: 100%;
  }
}

.project-box {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.project-box:hover {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.projects-main {
  margin-top: 18vh;
  margin-bottom: 6vh;
  margin-right: 120px;
}

.projects-header {
  overflow: hidden;
  /* position: absolute; */
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

.main-title h1 {
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
}

.main-title p {
  text-align: center;
}

.project-title {
  font-size: 1.5rem;
  text-transform: capitalize;
  font-weight: bold;
  padding: 0 1rem;
}

@media screen and (max-width: 765px) {
  .projects-main p {
    text-align: left;
  }
}

.project-description {
  padding: 0 1rem;
}

.blog {
  margin-top: 18vh;
  padding: 0 15vw;
  top: 11vw;
}

.design-blog {
  margin-top: 18vh;
  padding: 0 6vw;
  top: 11vw;
}

@media screen and (max-width: 765px) {
  .blog {
    padding: 0 7vw;
  }
}

.blog-title h1 {
  font-size: 2.5rem;
  line-height: 95%;
  padding: 2vw 0;
}

.blog h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.blog h3 {
  font-size: 1rem;
  font-weight: bold;
}

.blog a:hover {
  text-decoration: underline;
}

.blog ul {
  padding-left: 40px;
}

